<template>
	<div class="p_20 indexBanner">
		<el-dialog :visible.sync="isShow" title="编辑客户" :close-on-click-modal="false" @close="closeDialog">
			<!-- <el-divider></el-divider> -->
			<el-form :model="form" label-width="80px">
				<el-form-item label="客户名">
					<el-input v-model="form.name" class="w_400"></el-input>
				</el-form-item>
				<el-form-item label="客户类型">
					<el-select v-model="form.account_type_id">
						<el-option 
							v-for="item in opt"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShow: false,
				opt:[],
				form: {
					id:'',
					name:'',
					account_type_id:3
				},
			}
		},
		mounted(){
			this.getType();
		},
		methods: {

			getType(){
				this.axiosGets('Public/accountTypeList',{}, res => {
					// console.log(res);
					if(res.data.code === 200) {
						this.opt = res.data.data;
					}
					
				})
			},


			//提交
			submit(){
				
				let params = this.form;
				if(this.form.id) {
					this.edit(params);
				} else {
					this.add(params)
				}
			},
			
			
			//新增
			add(params){
				this.axiosGets('Client/add', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}else {
						this.msgError(res.data.message)
					}
				})
			},
			
			//编辑
			edit(params) {
				this.axiosPost('Client/editor', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}else {
						this.msgError(res.data.message)
					}
				})
			},


			//图片上传
			handleAvatarSuccess(res) {
				console.log(res);
				// console.log(file);
				//图片id
				this.form.image_id = res.data.id;
				//图片url
				this.imageUrl = this.imgURL + res.data.image_url;
				// this.form.url = res.data.image_url;
				// this.dialogVisible = true;
			},
			
			//关闭窗口 清空数据
			closeDialog(){
				this.form = {
					id:'',
					name:'',
					account_type_id:3
				}
				
				this.isShow = false;
			}
		}
	}
</script>

<style scoped>

</style>
