export default {
	// 获取页面数据
	getTableData() {
		var params = {
			// type:0,
			// language: this.language,
			account_type_id:this.id,
			page: this.$refs.Page.pagination.current,
			limit: this.$refs.Page.pagination.pageSize,
			// group_name:'',
			// client_name:''
		}
		this.onLoad(true)

		if(this.search) {
			if(this.type == 'client_name' ) {
				params.client_name = this.search;
			}
			if(this.type == 'group_name' ) {
				params.group_name = this.search;
			}
		}

		this.axiosPost("Client/clientList", params, function(res) {
			// console.log(res);
			if(res.data.code === 200) {
		this.onLoad(false)
				this.tableData = res.data.data.data;
				this.total = res.data.data.total
				// console.log(this.total);
				// this.msgSuccess(res.data.message)
			} else  {
				this.msgError(res.data.message)
			}
			//分页
			// this.total = res.data.data.total
			// console.log(this.total);
		})

	},

	add(){
		this.$refs.Edit.isShow = true;
	},


	//编辑
	edit(scope) {
		this.$refs.Edit.form.id = scope.id;
		this.$refs.Edit.form.name = scope.name;
		// this.$refs.Edit.form.account_type_id = scope.account_type_id;
		this.$refs.Edit.isShow = true;
	},

	//删除
	remove(scope) {
		this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {

			this.sureRemove(scope.banner_id);
		}).catch(() => {
			this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},

	//确定删除
	sureRemove(id) {

		this.axiosGets("index/homepage/banner_delete?banner_id=" + id, {}, function(res) {
			if (res.data.code === 0) {
				this.getTableData();
				this.msgSuccess(res.data.msg)
			} else {
				this.msgError(res.data.msg)
			}
		})
	}
}
