<template>
  <div>
    <div
      class="flex justify_content_between align_items_center content-wrapper p_lr_20 m_b_20"
    >
      <div class="flex align_items_center">
        <h2>客户管理</h2>
        <div class="p_l_20">
          <el-button size="small" type="primary" @click="add" v-if="isRole(['Client/add'])" class="m_r_10"
            >新建客户</el-button
          >
        </div>
      </div>
		<div>
			<span class="m_r_10">客户类型:</span>
			<el-select v-model="id" @change="getTableData" size="small" class="m_r_20">
				<el-option :value="1" label="系统"></el-option>
				<el-option :value="2" label="物流"></el-option>
				<el-option :value="3" label="客户"></el-option>
			</el-select>

      <el-select v-model="type" size="small" class="p_l_20 m_r_10">
				<el-option value="client_name" label="客户名"></el-option>
				<el-option value="group_name" label="组名"></el-option>
			</el-select>
			<el-input size="small" class="m_r_10 w_250" v-model="search"></el-input>
			<el-button size="small" type="primary" @click="getTableData"
				>搜索</el-button
			>

		</div>
    </div>

    <div class="content-wrapper p_20">
      <!-- -项目列表- -->
      <el-table :data="tableData" width="100%">
        <el-table-column label="创建时间" prop="created_at">
          <template slot-scope="scope">
            {{ scope.row.created_at|format }}
          </template>
        </el-table-column>
        <el-table-column label="客户名" prop="name"></el-table-column>
        <el-table-column label="操作" width="260" prop="name">
          <template slot-scope="scope">
            <el-button size="small" v-if="isRole(['Client/editor'])" @click="edit(scope.row)">编辑</el-button>
            <!-- <el-button size="small" @click="remove(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <page ref="Page" :total="total"></page>
      <Edit ref="Edit"></Edit>
    </div>
  </div>
</template>
<script type="text/javascript">
import methods from "./method";
import Edit from "./edit/edit";

export default {
  data() {
    return {
      tableData: [], //列表数据
      total: "", //数据总条数
      
      id: 3,

      type:'client_name',
      search:''
    };
  },
  methods: methods,
  components: {
    Edit,
  },
  mounted() {
    this.getTableData();
  },
};
</script>
